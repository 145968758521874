import React ,  { useState, useEffect } from 'react';
import Navigation from './Navigation';
import { useHistory, useLocation } from 'react-router-dom';
import {TelegramLogin} from './Utils';

 const Header = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const history = useHistory();
    const handleInputChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
          const link = searchLinkResult(searchTerm);
          history.push(link);
        }
    };

    const handleClick = () => {
        const link = searchLinkResult(searchTerm);
        history.push(link);
    };

    let location = useLocation();

    useEffect(() => {
        // Kiểm tra xem URL có tham số 's' không
        const searchParams = new URLSearchParams(location.search);

    }, [location]);

    
    function isValidWalletAddress(address) {
        if (!address) return false;
        const pattern = /^[1-9A-HJ-NP-Za-km-z]{32,44}$/;
        return pattern.test(address);
    }

    function searchLinkResult(searchTerm){
        const isAddress = isValidWalletAddress(searchTerm);
        if(isAddress) return `/token/${searchTerm}`;
        else return `/tokens?search=${searchTerm}`;
    }


    return (

        <div className="bg-primary border-head-bottom mx-auto md:flex p-2 pl-4 pr-4">
                <div className="w-full md:w-1/4 flex pt-1 max-sm:mb-2  items-center gap-4">
                    <Navigation/>
                    <div className="max-sm:w-full md:hidden">
                        {/* <WalletHeader/> */}
                        <TelegramLogin/>
                    </div>
                </div>


                <div className="w-full md:w-1/2 gap-4 flex items-center">   
                    <div className="search-form md:pl-4 w-full relative">
                        <input type="text" 
                            value={searchTerm}
                            onChange={handleInputChange}
                            onKeyPress={handleKeyPress}
                            placeholder="Token Address | Name" 
                            className="search w-full flex p-1"/>
                        <button className="button" type="submit" id="btn_find" onClick={handleClick}>
                            <i className="fa fa-search"></i>
                        </button>
                    </div>
                </div>

                <div className="w-full md:w-1/4 pt-1">
                    <div className="max-sm:hidden">
                        {/* <WalletHeader/> */}
                        <TelegramLogin/>
                    </div>
                </div>

        </div>
      

    );
}

export default Header