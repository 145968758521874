import { useParams} from 'react-router-dom';

const Footer = () => {

    // const { mint_address } = useParams();
    const botLink = "https://t.me/TokenCheckBetaBot";

    return (
            <div className="w-full mx-auto mt-10 text-center text-white">
                <div className='p-2 x-board bg-head rounded-md'>
            
                    {/* <span className="ml-4">
                        <a target="_blank" rel="noreferrer" href="https://x.com/tokencheck_io">[Twitter]</a>  
                    </span> */}

                    <span className="ml-4">
                        <a target="_blank" rel="noreferrer" href={botLink}>[Telegram]</a>  
                    </span>
                    
                </div>
            </div>
            
            )
}

export default Footer