import React from "react";

interface MUIButtonProps {
  children?: React.ReactNode;
  variant?: "contained" | "outlined" | "text";
  color?: "primary" | "secondary" | "error";
  onClick?: () => void;
  href?: string; // Thêm thuộc tính href
  target?: "_self" | "_blank"; // Thuộc tính target để hỗ trợ mở liên kết
}

const MUIButton: React.FC<MUIButtonProps> = ({
  children,
  variant = "contained",
  color = "primary",
  onClick,
  href,
  target = "_self", // Giá trị mặc định là _self
}) => {
  const baseClass =
    "px-2 py-1 rounded-lg font-medium focus:outline-none transition-transform transform active:scale-95";

  const variants = {
    contained: `text-white shadow-md`,
    outlined: `border-2`,
    text: `bg-transparent`,
  };

  const colors = {
    primary: {
      contained: `bg-blue-600 hover:bg-blue-700`,
      outlined: `text-blue-600 border-blue-600 hover:bg-blue-50`,
      text: `text-blue-600 hover:bg-blue-50`,
    },
    secondary: {
      contained: `bg-gray-600 hover:bg-gray-700`,
      outlined: `text-gray-600 border-gray-600 hover:bg-gray-50`,
      text: `text-gray-600 hover:bg-gray-50`,
    },
    error: {
      contained: `bg-red-600 hover:bg-red-700`,
      outlined: `text-red-600 border-red-600 hover:bg-red-50`,
      text: `text-red-600 hover:bg-red-50`,
    },
  };

  const variantClass = variants[variant];
  const colorClass = colors[color][variant];

  // Nếu có href, sử dụng <a>, nếu không, sử dụng <button>
  return href ? (
    <a
      href={href}
      target={target}
      className={`${baseClass} ${variantClass} ${colorClass}`}
      rel={target === "_blank" ? "noopener noreferrer" : undefined}
    >
      {children}
    </a>
  ) : (
    <button
      className={`${baseClass} ${variantClass} ${colorClass}`}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

export default MUIButton;
