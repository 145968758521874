import './App.css';
import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import Tokens from './pages/Tokens';
import Token from './pages/Token';
import Home from './pages/Home';

import ReactGA from 'react-ga4';
ReactGA.initialize('G-SFB7S88PVX');

function App() {
  //google analytics
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, []);


  return (
    <div className="App">
      <Router>
          <Switch>
              <Route path="/" exact component={Home}/> 
              <Route path="/tokens" component={Tokens} />
              <Route path="/token/:mint_address" component={Token} />
          </Switch>
      </Router>
    </div>
  );
  

}

export default App;
