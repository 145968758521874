export const PUMP_AUTHORITY = '39azUYFWPz3VHgKCf3VChUwbpURdCHRxjWVowf5jUJjg';

//time2 dùng để backup cho time1 nế time1 bằng NULL
export function varTime3(time1, time2 = null){ //open_time block_time
    var time;
    if(time1 > 0){
        time = time1;
        if(time1 < 10**9) time = time2;
    } 
    else if(time2) time = time2;

    if(time === 0 || time == null) return "-";

    const current = new Date();
    const current_unix = current.getTime()/1000;

    var ending, distance_time;
    if(current_unix > time){
        distance_time = current_unix - time;
        ending = 'ago';
    }
    else {
        distance_time = time - current_unix;
        ending = 'next';
    }

    var express_time = '';
    if(distance_time < 60) express_time = Math.floor(distance_time)+ "s ";
    else if(distance_time < 60 * 60) express_time = Math.floor(distance_time/60) + "' ";
    else if(distance_time < 24 * 60 * 60)  express_time = Math.floor(distance_time/3600) + "h ";
    else express_time = Math.floor(distance_time/24/3600) + "d ";

    if(ending === 'next')
        return (<span className="color-normal">{express_time} {ending}</span>) ;
    else 
        return (<>{express_time} {ending}</>) ;
}



export function varTime(time : any){ //second timestamp
    if(!time) return '-';
    const current_unix =  Date.now() /1000; //second unit
    const distance_time = current_unix - time;//second unit
    return formatTimestamp(distance_time);
}

export function formatTimestamp(distance_time : number){
    const sign = distance_time < 0 ?'+':'';
    const distance_time_abs = distance_time >= 0?distance_time : (0-distance_time);
    const formatTime = _formatTimestamp(distance_time_abs);
    return formatTime + sign;
}


export function _formatTimestamp(distance_time : number){ //second timestamp
    var day = Math.floor(distance_time/24/3600); 
    var remain_hour = distance_time - 24 * 3600 * day; //second
    var hour = Math.floor(remain_hour/3600);
    var remain_minute = remain_hour - 3600 * hour; //second
    var minute = Math.floor(remain_minute/60);
    var remain_second = Math.floor(remain_minute - 60 * minute); //second

    if(day > 0) return day + "d";// + hour + "h";
    if(hour > 0) return hour + "h";// + minute + "'";
    if(minute > 1) return minute + "'";// + remain_second + "s";
    if(minute === 1) return minute + "'" + remain_second + "s";

    return remain_second + "s";
}

export function utcTime(time){
    const date = new Date(time * 1000); 
                    const hours = date.getHours();
                    const minutes = date.getMinutes();
                    const seconds = date.getSeconds();
    return hours + ":" + minutes + ":" + seconds  + " UTC";// new Date(item.open_time * 1000);
}

export function distanceTime(time1, time2){
    var ago = time1 - time2;
    var ago_time = '';
    if(ago < 60) ago_time = ago + "s";
    else if(ago < 60 * 60) ago_time = Math.floor(ago/60) + "m";
    else if(ago < 24 * 60 * 60)  ago_time = Math.floor(ago/3600) + "h";
    else ago_time = Math.floor(ago/24/3600) + "d";
    return ago_time;
}



export function tokenNumber(res){
    if(res == null) return "-";
    const value = res.value;
    if(value == null) return "-";
    if(!value.hasOwnProperty("data")) return "";
    const data = value["data"];
    if(!data.hasOwnProperty("parsed")) return "";
    const parsed = data["parsed"];
    if(!parsed.hasOwnProperty("info")) return "";
    const info = parsed["info"];

    const supply = info["supply"];
    const decimals = info["decimals"];
    const true_supply =  Math.floor(supply / 10 ** decimals);
    return true_supply.toLocaleString('en-US');
}

export function realSupply(supply, decimals){
    if(decimals == null) return 0;
    if(supply == null) return 0;
    console.table({supply, decimals});
    const true_supply = Math.floor(supply / 10 ** decimals);
    return true_supply;
}

export function solNumber(lamports : number, showZero = true){
    if(lamports == null) return 0;
    if(lamports === 0 && showZero === false) return 0;
    const sol = lamports / 10 ** 9;

    return sol;
}

export function beautyNumber(number : number | undefined){
    if(number === 0) return 0;
    if(number == undefined) return "";

    if (Number.isInteger(number)) {
        return number.toLocaleString('en-US');
    }

    var new_number; 
    if (number >= 1000 ) 
        new_number =  number.toFixed(0);
    else if (number >= 0.1) 
        new_number =  number.toFixed(1);
    else if (number >= 0.01) 
        new_number = number.toFixed(2);
    else 
        new_number = number.toFixed(3);

    return Number(new_number).toLocaleString('en-US');
}


//keyword : name, symbol (code), uri
export function getMeta(keyword, value){
    if(value == null) return "";
    if(!value.hasOwnProperty("data")) return "";
    const data = value["data"];
   
    return data[keyword];
}


export function beautifyToken(value){
    if(value == null) return;
    if(value >= 1){
        const value2 = value.toFixed(0);
        const round = Math.floor(value2);
        
        return round.toLocaleString('en-US');
    }
    else return value.toFixed(2);
    
}

export function beautifyCap(value){ // dollar
    if(value == null) return;

    if(value >= 10**9){
        const m_number = value/10**9;
        return '$' + m_number.toFixed(2) + "B";
    }

    if(value >= 10**6){
        const m_number = value/10**6;
        return '$' + m_number.toFixed(2) + "M";
    }
    else if(value >= 1000){
        const k_number = value/1000;
        return '$' + k_number.toFixed(1) + "k";
    }
    else return '$' + value.toFixed(0);
    
}


export function makePercent(_part : number | undefined, _total : number | undefined){
    if(_part == undefined) return "-";
    if(_total == undefined) return "-";

    const part = Number(_part) || 0;
    const total = Number(_total) || 0;

    if(total === 0 ) return 0;
    const value = 100 * part / total;
    return value.toFixed(1);
}

export function extractSPL(instructions){
    if(instructions == null) return null;
    for(const ins of instructions){
        if(ins.type === "mintTo") {
            // return (Math.floor(ins.amount / 10 ** 9)).toLocaleString('en-US');
            return ins.amount;
        }
    }

    return null;
}

export function extractLP(liquidityTxs : LiquidityTx[], txId : string | undefined | null){
    if(!liquidityTxs) return '';
    if(!txId) return '';

    console.log("liquidityTxs", liquidityTxs);

    const amountItem = liquidityTxs.find(item => item.txId === txId);
    return amountItem?.amount || 0;
}

export function firstWord(slug){
    if(slug == null) return "";
    
    const words = slug.split("-");
    const capitalizedWords = words.map(word => { return word.charAt(0).toUpperCase() + word.slice(1);});
    // return capitalizedWords.join(" ");
    return capitalizedWords[0];
}

export function amountTransfer(instructions, sourceX){
    for(const ins of instructions){
        if(ins.source === sourceX) return ins.amount;
    }

    return null;
}

export function extractTokenCreatorName(obj){
    if(obj == null) return "-";
    if(typeof obj === 'string' ) return obj;
    if(obj.hasOwnProperty("name")) return obj["name"];

    return "-";
}

export function amountToken(data, mint){
    if(data == null) return null;
    for(const obj of data){
        if(obj["mint"] === mint) return obj["amount"];
    }

    return null;
}

export function typeMint(instructions){
    for(const ins of instructions){
        if(ins.hasOwnProperty("mint")) return ins.type;
    }

    return null;
}

export function amountMint(instructions){
    for(const ins of instructions){
        if(ins.hasOwnProperty("mint")) {
            if(ins.type === "burn") return ins.amount;
            if(ins.type === "burnChecked"){
                return ins.tokenAmount.amount;
            }
        }
    }

    return null;
}

/********** HOLDER ************/

export function graphHolders(holders){
    if(holders == null) return ;
    var output = [] as any[];
    var sum = 0;
    for(const item of holders){
        
        const value = Number(item.percent);
        sum += value;

        output.push({"name" : item.address, "value" : value});
    }

    if(sum < 1){
        const other = 1 - sum;
        output.push({"name" : "Other", "value" : other});
    }
    return output;
}

export function sumPercentHolders(holders : any[], poolAddress?: string){
    if(holders == null) return 0;

    // console.log(Supply);

    var sum = 0;
    for(const item of holders){
        if(item.address !== poolAddress){
            sum += 100 * Number(item.percent);
        }
    }
    return Number(sum.toFixed(2));
}

export function rateTopHolders(holders){
    const sumTop10 = sumPercentHolders(holders);
    if(sumTop10 >= 40){ //20
        return (<><i className="fa-solid fa-circle text-xs color-alarm"></i><span className="text-xs ml-2">Too Large</span></>)
    }
}




export function ratioChange(_number_new : any, _number_old : any){
    if(!_number_new) return "-";
    if(!_number_old) return "-";

    const number_new = Number(_number_new || 0);
    const number_old = Number(_number_old || 0);

    if(number_old === 0 ) return "-";

    if(number_new >= number_old ){
        const ratio_float =  number_new/number_old;
        if(ratio_float >= 2){
            const ratio_round = ratio_float.toFixed(1);
            return (<div className="color-good"><i className="fas fa-arrow-up md:hidden"></i> X{ratio_round} <i className="fas fa-arrow-up max-sm:hidden"></i></div>);
        }
        else{
            const ratio_percent = 100 * (number_new/number_old - 1);
            const ratio_percent_round = ratio_percent.toFixed(0);
            return (<><i className="fas fa-arrow-up color-good md:hidden"></i> +{ratio_percent_round}% <i className="fas fa-arrow-up color-good max-sm:hidden"></i></>);
        }
       
    }
    else {
    
        const ratio_percent = 100 * ( 1- number_new/number_old);
        const ratio_percent_round = ratio_percent.toFixed(0);
        return (<><i className="fas fa-arrow-down text-red-500 md:hidden"></i><span className="text-red-500"> -{ratio_percent_round}% </span> <i className="fas fa-arrow-down text-red-500 max-sm:hidden"></i></>);
    }
}




export function rateMintAuthority(mintAuthority){
    if(mintAuthority != null) {
        return (<><i className="fa-solid fa-circle text-xs color-alarm"></i><span className="text-xs ml-2">Mintable</span></>)
    }
}

export function rateSPLOwner(SplOwnerPercent){
    if(SplOwnerPercent > 80){
        // return (<div className="text-right"><i className="fa-solid fa-circle text-xs color-alarm"></i><div className="text-xs">Hold<br/>SPL</div></div>)
        return (<><i className="fa-solid fa-circle text-xs color-alarm"></i><span className="text-xs ml-2">Hold SPL</span></>)

    }
}

export function showRateAll(dataMint, mintAuthority, SplOwnerPercent, removeLiquid){
    if(dataMint != null){
        if(dataMint.burn === 1){
            return (<div className="flex items-center  justify-center"><i className="fa-solid fa-circle text-xs color-good mr-2"></i><div className="text-xs">Burn</div></div>)
        }

        if(dataMint.remove === 1){
            return (<div className="flex items-center  justify-center"><i className="fa-solid fa-circle text-xs color-bad mr-2"></i><div className="text-xs">Remove Liquidity</div></div>)
        }
    }
    if(removeLiquid > 0.9){
        return (<div className="flex items-center  justify-center"><i className="fa-solid fa-circle text-xs color-bad mr-2"></i><span className="text-sm">Remove Liquidity</span></div>)
    }

    if(SplOwnerPercent > 80){
        return (<div className="flex items-center  justify-center"><i className="fa-solid fa-circle text-xs color-alarm mr-2"></i><span className="text-sm">Rug Risk</span></div>)
    }

    if(mintAuthority != null){
        return (<div className="flex items-center  justify-center"><i className="fa-solid fa-circle text-xs color-alarm mr-2"></i><span  className="text-sm">Mint Risk</span></div>)
    }

    // 
}

export function showRateToken(burn_block : number, burn : number, remove : number, risk : number){
    let rateType : RateType = RateType.WARN;
    let rateText : String = "RISK";
    
    if(burn === 1 || burn_block === 1) {
        rateType = RateType.GOOD;
        rateText = 'BURN';
    }


    if(remove === 1){
        rateType = RateType.BAD;
        rateText = 'RUG!';
    }

    
    if(risk === 1){

    }

    return (<div className="flex items-center">{displayRate(rateType)}<span className="text-sm">{rateText}</span></div>);
}

export enum RateType {
    GOOD = 'good',
    WARN = 'warn',
    BAD = 'bad',
}

export function displayRate(type : RateType){
    switch(type){
        case RateType.GOOD:
            return (<i className="fa-solid fa-circle text-xs  color-good"></i>);
        case RateType.WARN:
            return (<i className="fa-solid fa-circle text-xs  color-alarm"></i>);
        case RateType.BAD:
            return (<i className="fa-solid fa-circle text-xs  color-bad"></i>);
    }

    return '';
}

export function showBurn(burn_block, burn){

    
    if(burn === 1 || burn_block === 1) {
        return (<div className="flex items-center"><i className="fa-solid fa-circle text-xs color-good mr-2"></i><span className="text-sm">Burn</span></div>)
    }
    else {
        return (<div className="flex items-center"><i className="fa-solid fa-circle text-xs color-alarm mr-2"></i><span className="text-sm">No Burn</span></div>)
    }
}

export function showRateMintAuthority(mintAuthority){
  if(mintAuthority !=  null){
        return (<div className="flex items-center"><i className="fa-solid fa-circle text-xs color-alarm mr-2"></i><span className="text-sm">Mintable</span></div>)
  }  
  else return ('');
  
}

export function showRateDexpaid(dexpaid, moonshot = 0){

    if(dexpaid === 1 || moonshot === 1){
        return (<div className="flex items-center"><i className="fa-solid fa-circle text-xs text-green-500 mr-2"></i><span className="text-sm">PAID</span></div>)
    }
    else return ('');
}



export function checkDexpaidInfo(json : any){
    const pairs = json?.pairs as any[];
    if(pairs == null) return null;
    const pair = pairs.find(item => item.info != null);
    const info = pair?.info;
    if(info == null) return null;
    const image = info?.imageUrl;
    const header = info?.header;
    const websites = info?.websites;
    const website = info?.websites.length > 0?websites[0].url: null;
    const socials = info?.socials as any[];
    const twitter_obj = socials?.find(item => item.type == 'twitter');
    const twitter = twitter_obj?.url;

    const telegram_obj = socials?.find(item => item.type == 'telegram');
    const telegram = telegram_obj?.url;

    return {image, header, website, twitter, telegram};
}

export function checkDexPair(json : any, dexId : string){
    const pairs = json?.pairs;
    if(pairs == null) return null;
 
    for(const pair of pairs){
        if(pair.dexId === dexId) return pair;
    }

    return null;
}

export function getDexMeta(pair){
    try{
        if(pair == null) return null;

        const info = pair["info"];
        if(info == null) return null;
        let output = {};
        const websites = info["websites"];
        let website = null;
        if(websites.length !== 0) website = websites[0]["url"];
        output["website"] = website;

        const socials = info["socials"];
        for(const x of socials){
            output[x["type"]] = x["url"];
        }

        // console.log(output);
        return output;
    }
    catch(ex){
        return null;
    }
}

export function dollarCap(dollar){
    if(dollar == null) return;
    const K_dollar = dollar/1000;
    return K_dollar.toFixed(1) + "K";
}


export function beautifyPercent(value){
    if(value == null) return ;
    if(value < 0)
        return (<span className="text-red-500">{value.toFixed(0)}%</span>);
    else 
    return (<span className="text-green-500">{value.toFixed(0)}%</span>);
}


/*------------------- NEW CODE -------------------------*/


// export function initSOL(pair){
//     if(!pair) return 0;
//     const {initLiquidity, order} = pair;

//     let lamports = order?initLiquidity.init_coin_amount:initLiquidity.init_pc_amount;
//     return solNumber(lamports);
// }

// export function initToken(pair){
//     if(!pair) return 0;
//     const {initLiquidity, order} = pair;

//     let number = !order?initLiquidity.init_coin_amount:initLiquidity.init_pc_amount;
//     return number;
// }

export function checkPump(pair){
    if(!pair) return 0;
    if(pair.initLiquidity?.authority === '39azUYFWPz3VHgKCf3VChUwbpURdCHRxjWVowf5jUJjg') return 1;
    else return -1;
}

export function findSPLAmount(list : SPLBalance[], address : string){
    const balance = list.find(item => item.account === address)?.balance;
    return balance;
}


export function solCap(lamports : number){
    const sol = lamports / 10 ** 9;
    const solFormated = beautyNumber(sol);

    return sol < 10 ?(<span className="text-red-500 px-1">{solFormated}</span>):solFormated;
}