import React, { useState, useEffect } from 'react';
import { useParams} from 'react-router-dom';
import IC_ADS from '../assets/ic_ads.svg';
import { ReactComponent as IC_MINT } from '../assets/ic_mint.svg';
import { ReactComponent as IC_MINT2 } from '../assets/ic_mint2.svg';
import { ReactComponent as IC_BURN } from '../assets/ic_burn.svg'; 
import { ReactComponent as MOONSHOT } from '../assets/moonshot.svg'; 
// import { ReactComponent as IC_RAYDIUM } from '../assets/raydium.png'; 


import { PieChart, Pie, Cell} from "recharts"; //Sector, 
import Footer from '../components/Footer';
import { LuCopy , LuCopyCheck} from "react-icons/lu";
import {   varTime, distanceTime,
        solNumber, firstWord, amountTransfer, 
        typeMint, amountMint, 
        beautifyCap,  beautifyPercent,
        sumPercentHolders, graphHolders, realSupply,
         ratioChange, rateTopHolders, rateMintAuthority , rateSPLOwner, showRateAll,
        makePercent, extractSPL, amountToken, showRateDexpaid, showRateMintAuthority,
        beautyNumber,
        
        PUMP_AUTHORITY,
       
        findSPLAmount,
        checkDexPair,
        checkDexpaidInfo,
        extractLP} from '../components/Helper';
import {shortTokenLink, shortTokenLink2, shortMintAuthorityLink, pumpLink,  raydiumLink, dexscreenerLink, metaLink, tokencheckLink, dexbullBot, tokencheckBot,} from '../components/Link';
import Header from 'src/components/Header';
import { API_DEXBULL_BASE, API_DSOL_BASE, API_SOL_BASE } from 'src/config';
import { initPool } from 'src/components/Utils';
import MUIButton from 'src/components/MUIButton';

/* eslint-disable react-hooks/exhaustive-deps */
const Token = () => {
    const { mint_address } = useParams();
    const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];
    
    const [token, setToken] = useState(null);
    const [tokenURIContent, setTokenURIContent] = useState(null);
    const [initLP, setInitLP] = useState(null);
    const [amountDevMint, setAmountDevMint] = useState(null);
    const [amountDevSPL, setAmountDevSPL] = useState(null);
    const [totalMint, setTotalMint] = useState(null);
   

    const handleCopy = (mint : string) => {
      navigator.clipboard.writeText(mint).then(() => {
        setToken({ ...token, copied: true });
  
        setTimeout(() => {
          setToken({ ...token, copied: false });
        }, 2000);
      });
    };

    /***************************************************************
     * AREA : LOAD TOKEN (MAIN)
     ***************************************************************/

    useEffect(() => {
        if(mint_address !== null){
          fetch(`${API_DEXBULL_BASE}/token-pairs/solana/${mint_address}`)
            .then(response => {
              return response.json(); 
            })
            .then(data => {
              const _token = data[0];
              setToken(_token);
              setInitLP(realSupply(extractLP(_token?.liquidityTxs, _token?.initLiquidity?.txId), _token?.ammKeys?.lpDecimals));
              setTotalMint(realSupply(_token?.info?.supply, _token?.info?.decimals));
            })
            .catch(error => {
                console.log('Fetch Error:', error);
            });
        } 
    }, [mint_address]);

    /***************************************************************
     * AREA : LOAD URI ==> IMAGE / LOGO
     ***************************************************************/

    useEffect(() => {
        if(token?.uri){
            fetch(token.uri)
                .then(response => {
                    return response.json(); 
                })
                .then(data => {
                    setTokenURIContent(data);
                })
                .catch(error => {
                    console.log('Fetch Error:', error);
                });
        }
    },[token]);
   
  
    /***************************************************************
     * AREA : TOP HOLDERS : START 
     ***************************************************************/
    const [holders, setHolders] = useState<HolderPercent>(null);

    useEffect(() => {
      if(token?.mint){ 
          fetch(`${API_SOL_BASE}/mint/holder/${token.mint}`)
            .then(response => {
                return response.json(); 
            })
            .then(data => {
                setHolders(data);
            })
            .catch(error => {
                console.log('Fetch Error:', error);
            });
          }
    }, [token]);



    /***********************************************************
     * AREA BALANCE 
     ***********************************************************/

    const [tokenBalance, setTokenBalance] = useState<Balance>(null);
    useEffect(() => {
      if(token?.mint){ 
        token_liquidity().finally(() => {
              // setInterval(() => {
              //     token_liquidity();
              // }, 10000);
        });
      }
        
      // return () => clearInterval(intervalId);
    }, [token]);

    //load liquidity khi có liquidityA và B
    async function token_liquidity(){
          fetch(`${API_DSOL_BASE}/mint/balance/${token?.mint}`,
              )
              .then(response => {
                  return response.json(); 
              })
              .then(data => {
                  setTokenBalance(data);
              })
              .catch(error => {
                console.log('Fetch Error:', error);
              });
    }


    /***********************************************************
     * AREA DEV:CHECK 
     ***********************************************************/

    

    useEffect(() => {
      const authority = token?.initLiquidity?.authority;
      if(authority){ 
        requestTokenByDev(authority, [token.mint, token.pool?.lpMint]);
      }
                  
    }, [token]);


    function requestTokenByDev(owner : string , tokens : string[]){
      const token_list = tokens.join(",");
      const url = `${API_SOL_BASE}/balance/owner/${owner}/${token_list}`;
          
      fetch(url)
        .then(response => {
            return response.json(); 
        })
        .then(data => {
            setAmountDevMint(findSPLAmount(data, token?.mint));
            setAmountDevSPL(findSPLAmount(data, token?.pool?.lpMint));
        })
        .catch(error => {
            console.log('Fetch Error:', error);
        });
    }

    /** all tx about raydium pool : burn, remove, add  */
    const [TxOwner, setTxOwner] = useState(null);
    
    function requestRaydiumTx(mint : string){
        fetch(`${API_SOL_BASE}/instruction/liquidity/${mint}`)
            .then(response => {
                return response.json();
            })
            .then(data => {
                setTxOwner(data);
            })
            .catch(error => {
                console.log('Fetch Error:', error);
            });
    }



    /************************************************************
     *  RATE : TOKEN
     * *********************************************************/
    const [rateAll, setRateAll] = useState(null);
    const [RemovePercent, setRemovePercent] = useState(0);
    


    function style_warning_percent(part : number, all : number){
        const ratio = part/ all;
        if(Number(ratio) > 0) return 'color-alarm';
        else return '';
    }
    
    useEffect(() => {
      if(TxOwner != null && token?.pool?.instructions){
          var total_spl = extractSPL(token?.pair?.instructions);
          var total_burn_spl = 0;
          TxOwner?.map((item, index) => {
              // const type_mint = typeMint(item.instructions);
              // console.log(item.type);
              if(item.type === "remove-liquid"){
                  const amount_mint = amountMint(item.instructions);
                  total_burn_spl += amount_mint;
              }

              return null;
          });

          if(total_spl !== 0){
            const ratio_burn = 100* total_burn_spl / total_spl;
            setRemovePercent(ratio_burn.toFixed(0));
          }
      }

    },[TxOwner]);  


    useEffect(() => {
      const _rateAll = showRateAll(token, token?.info?.mintAuthority, 0 , RemovePercent); //SPLDevPercent
      setRateAll(_rateAll);

    },[token, token?.info?.mintAuthority, 0 , RemovePercent, TxOwner ]); //SPLDevPercent
    

    /************************************************************
     *  REQUEST DEXSCREENER
     * *********************************************************/

    const [isRaydium, setIsRaydium] = useState(1);
    const [marketDex, setMarketDex] = useState(null);
    const [dexpaidInfo, setDexPaidInfo] = useState(null);
    const [moonshot, setMoonshot] = useState(0);


    useEffect(() => {
      if(mint_address != null){

        fetch('https://api.dexscreener.com/latest/dex/tokens/' + mint_address)
            .then(response => {
                return response.json(); 
            })
            .then(data => {
                setDexPaidInfo(checkDexpaidInfo(data));

                const raydium = checkDexPair(data, "raydium");
                if(raydium != null) {
                  setIsRaydium(1);
                  setMarketDex(raydium);
                }

                // const moonshot = checkDexId(data, "moonshot");
                // if(moonshot != null){
                //     setMoonshot(1);

                //     if(raydium == null){ // chỉ xét dến khi raydium = null
                //       setMarketDex(moonshot);
                //       // setDexMeta(getDexMeta(moonshot));
                //     }
                // }

                

            })
            .catch(error => {
                console.log('Fetch Error:', error);
            });
      }  
    }, [mint_address]);



    return (
      <>
        <Header/>
        <div className="container mx-auto p-4">
             <div className="w-full md:flex items-center">
                <div className="w-full md:w-1/3 flex items-center max-sm:mb-4">

                    <img src={token?.meta?.image} 
                        className="token-image mr-2" alt="" />
                    <div>
                      <div className="text-xl break-all">{token?.meta?.name || "-"}</div>
                      <div className="text-md">{token?.meta?.symbol || "-"}</div>
                      <div className='text-md text-yellow-200'>{varTime(token?.creation_ts/1000)}</div>
                    </div>

                    <div className="flex flex-col space-y-2 ml-auto md:hidden">
                        <MUIButton variant="contained" color="primary" href={tokencheckBot(mint_address, false)} target="_blank">CheckBot</MUIButton>
                        <MUIButton variant="contained" color="primary" href={dexbullBot(mint_address, false)} target="_blank">💰 BuyBot</MUIButton>
                    </div>
                    
                  
                </div>
                <div className="w-full md:w-1/3 flex max-sm:mb-4">
                  <div className="w-full text-center">
                    
                    <div className="x-board  rounded-md mt-2 flex relative">
                        {/* absolute left-4 */}
                        <div className="truncate pl-4">LIQUIDITY</div>
                        {/* w-full */}
                        <div className="mx-auto"><span className="color-good">{beautyNumber(tokenBalance?.sol)} </span> SOL 
                                    <i className="fa-solid fa-arrow-right-arrow-left ml-2 mr-2 text-slate-400"></i>
                                    <span>{makePercent(tokenBalance?.token * 10 ** token?.info?.decimals, token?.info?.supply)}%</span> Token
                        </div>
                        {/* absolute right-4 */}
                        <div className="truncate pr-4"> { ratioChange(Number(tokenBalance?.sol), initPool("sol", token?.initLiquidity, token?.ammKeys)) } </div>
                    </div>


                    {
                      marketDex?(
                          <div className="x-board  rounded-md mt-2 flex relative text-sm">
                              <div className="absolute left-2 flex gap-2">
                                  {token?.initLiquidity?.authority === PUMP_AUTHORITY?<img src="/images/exchanges/pumpfun.png" alt="pump-fun" className="h-[15px] w-[15px] rounded-full bg-white sm:h-[18px] sm:w-[18px]"/>:''}
                                  {
                                    isRaydium == 1?(<img className='w-5' src="https://dd.dexscreener.com/ds-data/dexes/raydium.png"/>)
                                    :(<MOONSHOT/>)
                                  }
                                </div>

                              <div className="mx-auto w-full gap-2">
                                  MC : <span className='text-green-300'> {beautifyCap(marketDex?.marketCap)}</span>

                                  <span className='ml-2 mr-2'>5' {beautifyPercent(marketDex?.priceChange?.m5)}</span>
                                  <span>1h {beautifyPercent(marketDex?.priceChange?.h1)}</span>
                                  <span className='ml-2'>24h {beautifyPercent(marketDex?.priceChange?.h24)}</span>
                              </div>
                          </div>
                      ):("")

                    }
                    

                    <div className="mt-2 flex gap-4 justify-center">{rateAll}
                      {/* {showRateDexpaid(dexpaid)} */}
                      
                      {showRateMintAuthority(token?.info?.mintAuthority)}
                    </div>

                    

                  </div>
                </div>
                <div className="w-full md:w-1/3  items-center max-sm:hidden">
                    <div className="md:float-right justify-center max-sm:flex max-sm:mb-4 space-x-2">
                        <MUIButton variant="contained" color="primary" href={tokencheckBot(mint_address, false)} target="_blank">CheckBot</MUIButton>
                        <MUIButton variant="contained" color="primary" href={dexbullBot(mint_address, false)} target="_blank">💰 BuyBot</MUIButton>
                    </div>
                </div>
             </div>

             <div className="w-full flex mt-4">
                <div className="w-full grid grid-cols-2 max-sm:grid-cols-1 md:space-x-4 max-sm:space-y-4">
                    <div>
                        <div className="x-board rounded-md justify-center p-4 relative">
                            <div className="absolute md:right-4 md:top-4 max-sm:right-2 max-sm:top-2"></div>
                            <table className="table-auto w-full">
                              <tbody>
                                  <tr><td className='w-32'>Token</td><td><div className="flex items-center">{shortTokenLink(mint_address , "token")} 
                                    <button className="px-2 py-2" onClick={() => handleCopy(token?.mint)}>{token && token.copied ?<span className="text-green-500"><LuCopyCheck /></span >:<span className="text-slate-500"><LuCopy /></span>}</button>
                                    
                                    
                                    
                                    </div>
                                  </td>
                                  </tr>
                                  <tr><td className='w-32'>Supply</td><td>{beautyNumber(realSupply(token?.info?.supply, token?.info?.decimals))}</td><td></td></tr>
                                  <tr><td className='w-32 align-top'>Mint Authority</td><td>{shortMintAuthorityLink(token?.info?.mintAuthority, "token")} 
                                        <span className='float-right'>{rateMintAuthority(token?.info?.mintAuthority)}</span>
                                  </td>
                                  </tr>
                                  {
                                    // (Supplies.length > 1)?(
                                    //     <tr><td className='w-32 align-top'>Mint+</td>
                                    //         <td className='color-normal'>{
                                    //           Supplies.map((sup, index) => {
                                    //                return (index === Supplies.length - 1)?
                                    //                   (<span>{sup}</span>)
                                    //                 : (<span>{sup}<i className="fa-solid fa-arrow-right ml-2 mr-2"></i></span>)
                                    //           })
                                    //         }
                                    //       </td>
                                    //     </tr>
                                    // ):(<></>)
                                    
                                  }

                                  <tr><td className='w-32 align-top'>SPL Token</td><td>{shortTokenLink2(token?.pool?.lpMint, "token")}</td></tr> 
                                 
                              </tbody>
                          </table>
                        </div>

                        <div className="x-board rounded-md justify-center mt-4 p-4">
                          <table className="table-auto w-full">
                              <tbody>
                                  
                                  <tr><td className='w-32 align-top'>Website</td><td><div className='break-all'>{metaLink("website", token?.meta?.website, dexpaidInfo)}</div></td></tr>
                                  <tr><td className='w-32 align-top'>Telegram</td><td><div className='break-all'>{metaLink("telegram",token?.meta?.telegram, dexpaidInfo)}</div></td></tr>
                                  <tr><td className='w-32 align-top'>Twitter</td><td><div className='break-all'>{metaLink("twitter", token?.meta?.twitter, dexpaidInfo)}</div></td></tr>
                                  <tr><td className="w-32 align-top">Description</td><td><div className='break-all whitespace-break-spaces'>{tokenURIContent?.description || '-'}</div></td></tr>
                                  
                              </tbody>
                          </table>
                        </div>


                        <div className="x-board rounded-md justify-center mt-4 p-4">
                          <table className="table-auto w-full">
                              <tbody>
                                  <tr><td className='w-32 align-top'>Promotion</td>
                                      <td className="dexscreener-ads">
                                        {
                                            (moonshot === 1)?(<div className='text-moonshot'>Moonshot</div>):("") 
                                        }
                                        
                                        <div className="align-top flex gap-1 items-center">Dexscreener {dexpaidInfo?(<><span className='color-ads'>Ads</span><img className="w-4 h-4" src={IC_ADS}/></>):
                                                            (<span className="text-red-600 font-bold">UNPAID</span>)}</div>

                                        {
                                            dexpaidInfo?.header?(<img className='mt-4' src={dexpaidInfo?.header} alt="Dexpaid!"/>):("")
                                        }
                                      </td>

                                      
                                  </tr>
                                 
                              </tbody>
                          </table>
                        </div>


                        <div className="x-board rounded-md justify-center mt-4 p-4">
                          <table className="table-auto w-full">
                              <tbody>
                                  
                                  <tr><td className='w-32 align-top'>See more +</td>
                                      <td><div className='break-all space-x-4'>
                                            {dexscreenerLink(mint_address, marketDex)}
                                            {raydiumLink(mint_address, isRaydium)}
                                            {token?.initLiquidity?.authority === PUMP_AUTHORITY?pumpLink(token?.mint):''}
                                            </div>
                                    </td>
                                  </tr>
                              </tbody>
                            </table>
                        </div>
                    </div>


                    <div>
                    
                     
                    <div className="x-board rounded-md justify-center p-4  mb-4 relative">
                        <table className="table-auto w-full"> 
                            <tbody>
                              {/* <tr>
                                  <td><b>🔎 Check Dev</b></td>
                              </tr> */}
                              <tr key="dev">
                                <td className="align-top"><div className="md:flex w-full">
                                      <div className="mr-4">Dev</div>
                                      <div className="md:float-right">{shortTokenLink2(token?.initLiquidity?.authority, "account")}</div>
                                    </div>
                                </td>
                                
                                <td className='align-top'>
                                    <div>{beautyNumber(amountDevMint)} <span className='text-slate-400'>Token</span></div>
                                    <div>{beautyNumber(amountDevSPL)} <span className='text-slate-400'>SPL</span></div>
                                </td>

                                <td className='align-top flex'>
                                  <div>
                                    <div>{makePercent(amountDevMint, totalMint)}% </div>
                                    <div className={style_warning_percent(amountDevSPL, initLP)}>{makePercent(amountDevSPL, initLP)}%</div>
                                  </div>
                                  <div className="w-full">
                                        {/* <div className='md:float-right'>{rateSPLOwner(SPLDevPercent)}</div> */}
                                  </div>
                                </td>
                              </tr>
                              
                              { token?.initLiquidity?(
                                <tr key="pool" className='border-t-8 border-transparent'>
                                    <td className='align-top'><div className="md:flex w-full">
                                          <div className="mr-4">Pool</div>
                                          <div className="md:float-right">{shortTokenLink2(token.initLiquidity?.txId, "tx")}</div>
                                        </div>
                                    </td>
                                  
                                  <td><div>
                                          <span className="color-good">{ beautyNumber(initPool("sol", token.initLiquidity, token.ammKeys))}</span> SOL
                                          <i className="fa-solid fa-arrow-right-arrow-left ml-2 mr-2 text-slate-400"></i>
                                          {beautyNumber(initPool("token", token.initLiquidity, token.ammKeys))} <span className='text-slate-400'>Token</span>
                                      </div>
                                      <div>
                                          {
                                            initLP?
                                              (
                                                <div className="w-full flex">
                                                  <div className="mr-2"><IC_MINT/></div>
                                                  <div className="mr-2"> {beautyNumber(initLP)} <span className='text-slate-400'>SPL</span> </div>
                                                </div>  
                                              )
                                              : ""
                                          }
                                      </div>
                                      <div>
                                        {
                                          // (dataMint?.burn_block === 1)?
                                          //     (
                                          //       <div className="w-full flex">
                                          //         <div className="mr-2"><IC_BURN/></div>
                                          //         <div className="mr-2"> 100%</div>
                                          //       </div>  
                                          //     )
                                          //     : ""
                                        }

                                      </div>
                                    
                                  </td>

                                  <td className="align-top">
                                      <div className="md:flex">
                                          <div className="mr-4">
                                                <div>{makePercent(initPool("token", token.initLiquidity, token.ammKeys), token.info.supply / 10 ** token.info.decimals)}% </div>
                                                <div className='text-slate-400'>Token</div>
                                          </div>
                                          <div className="w-full">
                                              <div className="md:float-right">{varTime(token.creation_ts / 1000)}</div>
                                          </div>
                                      </div>
                                  </td>

                                </tr>
                              )
                              :(<></>)
                              } 


                              {
                                TxOwner?.map((item : any, index : number) => {
                                    
                                    if(item != null) 
                                    {
                                      if(item.signature === token?.pool?.txId || !item.hasOwnProperty("type")) return;


                                      const amountA = amountTransfer(item.instructions, token?.pool?.baseVault); //liquidityA ==> baseVault
                                      const amountB = amountTransfer(item.instructions, token?.pool?.quoteVault);
                                      const amount_mint = amountMint(item.instructions);
                                      var type_mint = typeMint(item.instructions);
                                      if(type_mint === "burnChecked") type_mint = "burn"; //mint. transfer. burn 

                                      const type = item.type ;// remove-liquid....
                                      
                                      return (<tr key="txOwner_{index}" className='border-t-8 border-transparent'> 
                                                  <td className='align-top'><div className="md:flex w-full">
                                                        <div className="mr-4">{firstWord(item.type)}</div>
                                                        <div className="md:float-right">{shortTokenLink2(item.signature, "tx")}</div>
                                                      </div>
                                                  </td>                                                  
                                                  <td className='align-top'>
                                                     {
                                                        (type === "remove-liquid")?(                                                     
                                                            <div className="w-full flex items-center gap-1">
                                                                        {beautyNumber(realSupply(amountA, token?.info?.decimals))} <span className='text-slate-400'> Token</span>
                                                                        { amountA?(<i className="fa-solid fa-arrow-right-arrow-left ml-2 mr-2 text-red-600"></i>): ""}
                                                                        { solNumber(amountB) } SOL
                                                            </div>
                                                        ):("")
                                                      }

                                                      <div className="w-full flex items-center">
                                                          {
                                                            (type_mint === "mintTo")?(<div className="mr-2"><IC_MINT2/></div>): (<div className="mr-2"><IC_BURN/></div>)
                                                          }
                                                          
                                                          <div>{beautyNumber(realSupply(amount_mint, token?.info?.supply))} <span className='text-slate-400'>SPL</span></div>
                                                      </div>

                                                  </td>
                                                  <td className='align-top'><div className="md:flex">
                                                        <div className="w-full"><div className="md:float-right text-slate-400">+{distanceTime(item.blockTime, token?.creation_ts)}</div></div>
                                                      </div>
                                                  </td>

                                              </tr>
                                    
                                      )
                                      

                                    }
                                    else return null;
                                  
                              })}
                              
                              
                          </tbody>
                        </table>

                        
                    </div>
                    
        
                          

                    <div className="x-board rounded-md justify-center mt-4 p-4 min-h-40 flex relative">
                          {/* <div className="absolute md:right-4 md:top-4 max-sm:right-2 max-sm:top-2">{rateTopHolders(HolderPercent)}
                                
                          </div> */}
                          <table className="table-auto w-1/2">
                            

                              <tbody>
                                {/* <tr><td><b>🔎 Check Holders</b></td>
                                </tr> */}
                                <tr><td className="underline underline-offset-4">TOP 10 Holders</td>
                                    <td className="underline underline-offset-4">{sumPercentHolders(holders)} %</td>
                                </tr>
                                {
                                  holders?.map((item : HolderPercent, index :number) => {
                                        var plus = "";
                                        if(token){
                                          if(item.address === token?.pool?.quoteVault) plus = "(Pool)"; 
                                          else if(item.address === token?.pool?.authority) plus = "(Dev)";
                                        }
                                  
                                        
                                        return (
                                          <tr key={index}><td>{shortTokenLink2(item.address, "account")} <span>{plus}</span></td><td className="text-gray-500">{ (item.percent * 100).toFixed(2) + "%"}</td></tr>
                                        )
                                    
                                  }
                                )}
                              </tbody>
                          </table>   

                          <div className="w-1/2">
                                <PieChart className="mx-auto max-sm:p-4" width={200} height={200}>
                                      <Pie
                                        data={graphHolders(holders)}
                                        cx={100}
                                        cy={100}
                                        innerRadius={50}
                                        outerRadius={80}

                                        // labelLine={false}
                                        // label={renderCustomLabel}

                                        fill="#8884d8"
                                        paddingAngle={3}
                                        dataKey="value"
                                      >
                                        {graphHolders(holders)?.map((entry, index) => (
                                          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                        ))}
                                      </Pie>
                                  </PieChart>
                            

                              <div className='text-center'>
                                  <div className="flex gap-2 justify-center">
                                      <div>{rateTopHolders(holders)}</div>
                                  </div>
                              </div>
                          </div>
                      </div>
                    </div>

                    
                </div>
                

             </div>

             <Footer/>
            
        </div>
      </>
    )
}

export default Token